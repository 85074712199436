import * as actionTypes from './actionTypes';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { authFBS, dbFBS } from '../../firebase';
import { messageErrorFirebase } from '../../share/errorsFirebaseUtility';
import { doc, getDoc } from 'firebase/firestore';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
};

export const authSuccess = (tokenFBS, userUID, email) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        tokenFBS: tokenFBS,
        userUID: userUID,
        email: email,
    }
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
};

export const logout = () => {
    localStorage.removeItem('tokenFBS');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('user');
    localStorage.removeItem('email');
    localStorage.removeItem("Nombre");
    localStorage.removeItem("Usuario");
    return {
        type: actionTypes.AUTH_LOGOUT
    }
};

export const checkAuthTimeOut = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            console.log("TimeOutDesconectado con exito");
            dispatch(logoutFBS());        
        }, expirationTime * 10000)
    }
}

export const authFBSProc = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        signInWithEmailAndPassword(authFBS, email, password)
            .then((userCredential) => {   
                try{
                    const userInfo = userCredential.user;
                    let tokenFBS = userInfo.accessToken;
                    let userUID = userInfo.uid;
                    const expirationDate = new Date(new Date().getTime() + 3600 * 1000);                
                    localStorage.setItem('tokenFBS', tokenFBS);
                    localStorage.setItem('expirationDate', expirationDate);
                    localStorage.setItem('userUID', userUID);
                    localStorage.setItem('email', email);
                    getSetUserName(userUID);
                    dispatch(authSuccess(tokenFBS, userUID, email));
                    // dispatch(checkAuthTimeOut(expirationDate));
                } catch(err){
                    console.log(err);
                }
            })
            .catch((error) => {
                let errorMessage = "No_Data_Error";
                errorMessage = messageErrorFirebase(error.code);
                dispatch(authFail(errorMessage));
            })
    }
}

export const getSetUserName = async(userUID) => {
    const docRef = doc(dbFBS, 'usuarios', userUID);
    try {
        const docSnap = await getDoc(docRef);
        if (docSnap) {
            const data = docSnap.data();
            localStorage.setItem("Nombre", data.nombre);
            localStorage.setItem("Usuario", data.usuario);
        } else {
            console.log("No such document!");
        }
    } catch (error) {
        console.log(error);
    }    
}

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    }
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('tokenFBS');
        if (!token) {
            console.log("Sin Token Desconectado con exito");
            dispatch(logoutFBS());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            const userId = localStorage.getItem('user');
            dispatch(authSuccess(token, userId));
            if (expirationDate <= new Date()) {
                console.log("Expiration Date Desconectado con exito");
                dispatch(logoutFBS());
            } else {
                const userId = localStorage.getItem('userId');
                dispatch(authSuccess(token, userId));
                // dispatch(checkAuthTimeOut((expirationDate.getTime() - new Date().getTime()) / 10000));
            }
        }
    }
}

export const logoutFBS = () => {
    return dispatch => {
        try {
            signOut(authFBS).then(() => {
                dispatch(logout());            
            }).catch((error) => {
                console.log("No pude desconectarme.")
            })
        } catch (error) {
            console.log("No pude desconectarme");
        }
    }
}